.footerHead{
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 1.5rem;
}
.footerColumn{
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 3rem;
}
.footerColumn p{
  font-weight: 700;
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 0.25rem;
}
.footerColumn a{
  text-decoration: none;
  color: #FFFFFF !important;
  font-size: 1rem;
  transition: color 0.5s;
}
.footerColumn a:hover{
  color: #44CCFF !important;
}
.footerColumn img{
  height: 0.75rem;
  width: 0.75rem;
}
.footerIcons {
  margin-right: 0.5rem;
  fill: #FFFFFF;
}
.subFooter{
  height: 3rem;
  color: #7c7c7c;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  gap: 0.8rem;
  margin: auto 0.8rem;
}
.socialsContainer{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 1.5rem;
}
.socialsContainer .followUs{
  font-weight: 700;
  font-size: 1.25rem;
}
.subFooterRight{
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}
.subFooterRight svg path{
  cursor: pointer;
}
.subFooterRight svg:active path{
  fill: #44CCFF !important;
}