.container {
  display: flex;
}

.learnSidenav {
  width: 16.667vw;
  height: 100%;
  position: sticky;
  z-index: 1;
  top: 4.5vw;
  background: #000;
  overflow-x: hidden;
  border: 2px solid #2e2d2d;
}

.learnSidenav a {
  border-bottom: 2px solid #2E2D2D;
  padding: 0.75vw;
  text-decoration: none;
  font-size: 1.154vw;
  color: #ccc;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1.923vw;
}

.learnSidenav a:hover {
  color: #44ccff;
}

.pageWrapper {
  margin: 3vw 0vw 0vw 2vw;
  width: 61.09vw;
  border-right: 2px solid #333333;
}

.pageTitle {
  font-size: 2.051vw;
  margin: 2.051vw 0;
}

.paraStyle {
  font-size: 1.154vw;
  line-height: 1.448vw;
  letter-spacing: 0.04em;
  padding: 0%;
  margin-top: 0.769vw;
  padding-right: 2.5vw;
}

.subTitle {
  font-size: 1.282vw;
  color: #44ccff;
  font-weight: 500;
}

.paraDiv {
  margin-top: 1.538vw;
}

.requestCallButton {
  padding: 1.018vw 2vw;
  border-radius: 5px;
  border: none;
  font-size: 1.026vw;
  width: fit-content;
  background-color: #44CCFF;
  cursor: pointer;
}

.requestCallButton:hover {
  color: white;
}

.imageContainer {
  display: flex;
  justify-content: center;
}

.consultationContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* margin: 10.128vw 8.205vw; */
  padding-right: 2.5vw;
  margin-bottom: 0;
}

.consultationContainer h2 {
  margin-bottom: 0;
  font-size: 2.051vw;
  font-weight: bold;
}

.consultationContainer p {
  font-size: 1.026vw;
  font-weight: normal;
  color: #cccccc;
}

.consultButton {
  padding: 1.018vw 2.3vw;
  border-radius: 3px;
  border: none;
  font-size: 1.282vw;
  width: fit-content;
  background-color: #44CCFF;
  cursor: pointer;
}

.consultButton:hover {
  /* transform: scale(1.2); */
  color: white;
}

.image {
  width: 33.36vw;
  height: 23.07vw;
}