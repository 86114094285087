.learnSidenav {
    width: 16.667vw;
    height: 100%;
    position: sticky;
    z-index: 1;
    top: 4.5vw;
    background: #000;
    overflow-x: hidden;
    border: 2px solid #2e2d2d;
}

.learnSidenav a {
    border-bottom: 2px solid #2E2D2D;
    height: 4.615vw;
    text-decoration: none;
    font-size: 1.282vw;
    color: #ccc;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1.923vw;
}

.learnSidenav a:hover {
    color: #44ccff;
}

.pageWrapper {
    margin: 4.487vw 0 2vw 17vw;
    margin-top: -11%;
}

.partOne {
    padding: 1.282vw 0 0 3.333vw;
    margin-top: 2vw;
}

.partOneAuth {
    padding: 1.282vw 0 0 1.282vw;
}

.partTwo {
    padding: 1.282vw 0 0 3.333vw;
}

.partTwoAuth {
    padding: 1.282vw 0 0 1.282vw;
}

.partThree {
    padding: 2.564vw 0 0 3.333vw;
}

.partThreeAuth {
    padding: 2.564vw 0 0 1.282vw;
}

.subHeadings {
    font-size: 1.538vw;
    margin-bottom: 1.795vw;
}

.cardContent {
    font-size: 1.538vw;
    color: #FFF;
}

.subTitle {
    font-size: 1.026vw;
    color: #FFF;
}

.cardContainer {
    display: flex;
    flex-direction: row;
    gap: 1vw;
    overflow-x: scroll;
}

.readMore {
    width: 6.513vw;
    border-bottom: 1px solid #333333;
}

.readMeLine {
    width: 0;
    height: 1px;
    margin: 0;
    background-color: #44CCFF;
    margin-top: 2px;
}

.readMore:hover .readMeLine {
    width: 100%;
    transition: width 0.5s;
}