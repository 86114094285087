.footerContainer{
  margin: auto 2.308vw;
  margin-top: 3.333vw;
}
.footerHead {
  height: 16.026vw;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
}
.footerHeadTitle{
  display: flex;
  align-items: center;
  gap: 0.8vw;
}
.subFooter{
  height: 5.641vw;
  display: flex;
  
  justify-content: space-between;
  align-items: center;
  font-size: 1.026vw;
  margin: auto 2.308vw;
}
.footerColumn{
  display: flex;
  flex-direction: column;
  gap: 1.282vw;
  justify-content: start;
  flex: 1;
}
.footerColumn p{
  margin: 0;
  font-size: 1.538vw;
}
.footerColumn a{
  font-size: 1.282vw;
  transition: color 0.5s;
}
.footerColumn a:hover{
  color: #44CCFF;
}
.subFooterRight{
  display: flex;
  align-items: center;
  gap: 0.769vw;
}
.subFooterRight svg path{
  cursor: pointer;
}
.subFooterRight svg:active path{
  fill: #44CCFF !important;
 }