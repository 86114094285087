.topBar {
  position:fixed;
  top: 0;
  height: 2.5rem;
  width: 100%;
  border-top: 1px solid #333333;
  background: #000000;
  display: flex;
  border-bottom: 0.5px solid #333333;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  z-index: 2;
}

.toolbarToggle button {
  display: flex;
  width: 2rem;
  margin: 0.25rem 0 0 0.5rem;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
}

.toolbarToggle button span {
  width: 100%;
  margin: 0.2rem;
  border: 0.5px solid #cccccc;
}

.topBar .topBarRight {
  display: flex;
  margin: 0.25rem;
  justify-content: right;
  align-items: center;
  gap: 0.75rem;
}

.coinTracker {
  display: flex;
  width: 4.5rem;
  background: #2e2d2d4b;
  border: 1px solid #333333;
  border-radius: 0.5rem;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  font-size: 0.75rem;
}

.coinTracker span {
  margin: 0.25rem;
  color: #fff;
}
.notificationBell {
  margin-right: 1rem;
  margin-top: 0.375rem;
}

.bodyContainer {
  margin: 0.75rem;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  bottom: 0;
  height: 2.5rem;
  width: 100%;
  border-top: 1px solid #333333;
  background: #000000;
  z-index: 2;
}
.footer .buttonContainer {
  margin: 0.25rem 1rem;
  position: fixed;
  display: flex;
  gap: 11vw;
  align-items: center;
}

.footer .buttonContainer button:hover {
  cursor: pointer;
}