.topButton{
    position: fixed !important;
    border: 1px solid #333333 !important;
    border-radius: 0 !important;
    padding: 0 !important;
}

.mainNotificationDrawer{
    width: 35.949vw;
    padding-left: 1.538vw;
    margin-top: 2vw;
}

.list{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* width: fit-content; */
    
}

.firstSpan{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1vw;
}

.svgComponent{
    width: 1.09vw;
    height: 1.09vw;
}


.closeButton{
    display: none !important;
}


.list div {
    display: flex;
    justify-content: space-between;
    width: 32.821vw;
  padding: 1vw;
  border: 1px solid #333333;
  margin-bottom: 1.026vw;
  border-radius: 10px;
  font-family: Mulish;
  font-size: 1.026vw;
  font-weight: 400;
  line-height: 1.287vw;
  text-align: left;
}

@media screen and (max-width:768px){
    .notificationDrawer{
        position: relative;
    }

    .topButton{
        display: none !important;
    }

    .firstSpan{
        gap:0.4rem;
        /* width: 805; */
        width: 90%;
        /* overflow: hidden; */

    }

    .mainNotificationDrawer{
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }

    .mainNotificationDrawer h2{
        font-size: 1.125rem;
        align-self: flex-start;
        margin-left: 1.25rem;
    }

    .list div{
        width: 22.25rem;
        font-size: 0.75rem;
        padding: 1rem;
        margin-bottom: 0.5rem;
        line-height: 0.8rem;
        /* overflow: hidden; */
        /* white-space: nowrap; */
        /* text-overflow: ellipsis; */
    }

    .svgComponent{
        width: 1rem;
        height: 1rem;
    }

    .closeButton{
        display: block !important;
        position: absolute;
        top: 0px;
        position: fixed !important;
        right: 0px !important;
        left: 3px !important;
        width: fit-content;
        padding-left: 3px !important;
        padding-top: 3px !important;
    }
    
}
