.topBar {
  position: fixed;
  top: 0;
  height: 4.5vw;
  width: 100%;
  border-top: 1px solid #333333;
  background: #000000;
  display: flex;
  border-bottom: 0.5px solid #333333;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  z-index: 1250;
}

.logoIcon {
  margin-left: 1.5vw;
  height: 2.604vw;
  width: 10.67vw;
  margin-bottom: 0.256vw;
}

.iconStyle {
  height: 3.25vw;
  width: 14vw;
}

.topBar .topBarRight {
  display: flex;
  /* margin: 0 1vw 0 0; */
  justify-content: right;
  align-items: center;
  gap: 1vw;
  margin-right: 3vw;
}

.coinTracker {
  display: flex;
  width: 6.01vw;
  height: 3.125vw;
  background: #2e2d2d4b;
  border: 1px solid #333333;
  border-radius: 0.5rem;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  font-size: 0.769vw;
  /* margin-right: 1vw; */
}

.coinTracker span {
  margin: 0.25vw;
  color: #fff;
}

.notificationBell {
  /* margin-right: 1vw; */
  /* height: 1.45vw; */
  /* height: auto;
  width: 1.45vw; */
}

.bodyContainer {
  margin: 0.75vw;
  overflow: hidden;
  /* visibility: unset; */
  height: 100%;
  /* z-index: -10; */
}

.sideBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 4vw;
  border-right: 1px solid #333333;
  background: #2E2D2D40;
  z-index: 2;
  justify-content: space-between;
  height: 100vh;
}

.sideBar .buttonContainer {
  /* margin: 0.25vw; */
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 1.80rem;
  align-items: center;
  margin-top: 1vw;
}

.sideBar {
  margin: 4.5vw 0 0 0;
  justify-content: space-between;
}

.sideBar .buttonContainer button:hover {
  cursor: pointer;
}

.cartDiv {
  height: 1.45vw;
  width: 1.45vw;
  /* margin-right: 1vw; */
}

.profileIcon {
  border-radius: 50%;
  border: 1px solid;
  height: 1.87vw;
  width: 1.87vw;
}

.div2 {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.logoutIcon {
  object-fit: auto;
  object-position: center;
  width: 1.66vw;
}

.socialIconsContainer {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.socialIcons {
  object-fit: auto;
  object-position: center;
  width: 1.66vw;
  height: 1.66vw;
}

.sidenavFooter {
  display: flex;
  /* margin-top: 4vw; */
  margin-left: 0.09375vw;
  font-size: 0.875vw;
  color: #cccccc;
  align-items: flex-end;
}

.nameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25vw;
}