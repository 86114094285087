.MuiToolbar-root {
    padding-right: 0%;
  }
  
  .sidenavContainer {
    display: flex;
    flex-direction: column;
    /* position: fixed; */
    width: 100%;
    top: 7vw;
  }
  
  .sidenavHeader {
    align-items: center;
    display: flex;
    width: 100%;
    gap: 3.3vw;
    font-size: 0.962vw;
    color: #ccc;
    font-weight: 400;
    white-space: nowrap;
    padding: 0 1.282vw;
    /* padding-top: 1vw; */
  }
  
  .sidenavLinks {
    display: flex;
    width: 3.846vw;
    gap: 1.026vw;
    font-size: 0.897vw;
    color: #ccc;
    font-weight: 400;
    white-space: nowrap;
    margin: 0.6875rem 0 0 1.25rem;
    align-items: center;
    cursor: pointer;
  }

  .sidenavLinks:hover {
    color: #fff;
  }
  
  .separator {
    background-color: #333;
    min-height: 1px;
    margin-top: 0.6875rem;
    width: 111%;
  }
  
  .sidenavFooter {
    display: flex;
    /* width: 3.75rem; */
    gap: 0.641vw;
    font-size: 0.833vw;
    color: #ccc;
    font-weight: 400;
    white-space: nowrap;
    margin: 1vw 0 0 1.282vw;
    align-items: center;
    cursor: pointer;
  }
  
  .sidenavFooter:hover {
    color: #fff;
  }

  .SidenavIcon {
    object-fit: auto;
    object-position: center;
    width: 100%;
    flex: 1;
    /* height: 1.603vw;
    width: 1.603vw */
  }
  
  .profileIcon {
    border-radius: 50%;
    border: 1px solid;
  }
  
  .smartsaIcon {
    object-fit: auto;
    object-position: center;
    width: 1.282vw;
    align-self: start;
  }
  
  .logoutIcon {
    object-fit: auto;
    object-position: center;
    width: 1.154vw;
  }