body {
  margin: 0;
  font-family: "Mulish", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000;
  color: #cccccc;
  overflow-x: hidden; /* page going out of screen: right side */
}


html,body,*{
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent; /* For removing Blink effect Our Story(mobile) */
}

/* *{
  -ms-overflow-style: none;
} */
::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @media screen and (max-width: 37.5rem) {
  body {
    width: 25.75rem;
  }
} */

.heading {
  font-weight: normal;
  margin: 0%;
  padding: 0%;
  font-size: 1.125rem;
}

.headingDesktop {
  font-weight: normal;
  margin: 0%;
  padding: 0%;
  font-size: 2.564vw;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.pageContainer {
  margin-top: 3.25rem;
}

.pageContainerDesktop {
  margin: 5.5vw 2vw 0 4.5vw;
}

.questionPage {
  font-size: 1rem;
  color: #cccccc;
}

.questionPageDesktop {
  font-size: 1.154vw;
  color: #cccccc;
}

.instructionsContainer {
  margin: 1rem 0rem;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem;
  border-radius: 10px;
  border: 3px solid #333333;
}

.instructionsContainerDesktop {
  margin: 1.026vw 0rem;
  font-weight: 500;
  font-size: 1.026vw;
  line-height: 1.25;
  padding: 0.769vw;
  border-radius: 10px;
  border: 3px solid #333333;
  width: 30vw;
}

.instructionsHeader {
  margin-bottom: 0.375rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.instructionsHeaderDesktop {
  margin-bottom: 1vw;
  font-size: 1.538vw;
  font-weight: 600;
  line-height: 1.5rem;
}

.authButtons {
  padding: 0% !important;
  height: 2.8125rem !important;
  font-size: 0.875rem !important;
  text-transform: capitalize !important;
  background-color: #44ccff !important;
  width: 28.125vw;
}

.authMobile {
  padding: 0% !important;
  height: 2.8125rem !important;
  font-size: 0.875rem !important;
  text-transform: capitalize !important;
  background-color: #44ccff !important;
}

#pdf-container{
  pointer-events: none !important;
  user-select: none;
}

/* Add padding to Swiper container to accommodate scaling */
.mySwiper {
  padding: 0 0.962vw; /* Adjust based on how much extra space you need */
  overflow: hidden; /* Hide overflow to prevent clipped content */
}

.mySwiper .swiper-wrapper {
  padding: 2.885vw 0.962vw; /* Add padding inside the wrapper if needed */
}

.mySwiper .swiper-slide {
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0.8; /* Dim non-center slides for better effect */
}

.mySwiper .swiper-slide-active {
  transform: scale(1.1); /* Scale up the center slide */
  opacity: 1; /* Fully opaque for the center slide */
}
html {
  scroll-behavior: smooth;
}
/* Mobile-specific styles */
@media (max-width: 767px) {
  .mySwiper {
    padding: 0 3.641vw; /* Adjust based on how much extra space you need on mobile */
  }

  .mySwiper .swiper-wrapper {
    padding: 10.922vw 3.641vw; /* Add padding inside the wrapper for mobile */
  }

  .mySwiper .swiper-slide {
    opacity: 0.7; /* Dim non-center slides more on mobile */
  }

  .mySwiper .swiper-slide-active {
    transform: scale(1.05); /* Scale up the center slide less on mobile */
    opacity: 1; /* Fully opaque for the center slide */
  }
}

.video-responsive {
  overflow: hidden;
  position: relative;
  width: 54.744vw;
  height: 30.793vw;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.MuiGrid-root > .MuiGrid-item {
  padding: 16px 0 0 16px !important;
}

.signupFields {
  width: 32.051vw !important;
}