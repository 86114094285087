.MuiToolbar-root {
  padding-right: 0%;
}

.sidenavContainer {
  display: flex;
  flex-direction: column;
}

.sidenavHeader {
  align-self: left;
  display: flex;
  width: 100%;
  gap: 5rem;
  font-size: 0.9375rem;
  color: #ccc;
  font-weight: 400;
  white-space: nowrap;
  padding: 0 0.9375rem;
}

.sidenavLinks {
  display: flex;
  /* width: 3.75rem; */
  gap: 0.625rem;
  font-size: 0.8125rem;
  font-weight: 400;
  white-space: nowrap;
  margin: 0.6875rem 0 0 0.9375rem;
  align-items: center;
  cursor: pointer;
}

.separator {
  /* background-color: #333; */
  min-height: 1px;
  margin-top: 0.6875rem;
  width: 100%;
}

.sidenavFooter {
  display: flex;
  /* width: 3.75rem; */
  gap: 0.625rem;
  font-size: 0.8125rem;
  font-weight: 400;
  white-space: nowrap;
  margin: 0.6875rem 0 0 0.9375rem;
  align-items: center;
  cursor: pointer;
}

.SidenavIcon {
  object-fit: auto;
  object-position: center;
  width: 100%;
  flex: 1;
}

.profileIcon {
  border-radius: 50%;
  border: 1px solid;
}

.socialIconsContainer {
  display: flex;
  gap: 1rem;
  margin-left: 0.9375rem;
  margin-top: 0.9375rem;
}

.socialIcons {
  object-fit: auto;
  object-position: center;
  width: 1.5rem;
}

.smartsaIcon {
  object-fit: auto;
  object-position: center;
  width: 1.25rem;
  align-self: start;
}

.logoutIcon {
  object-fit: auto;
  object-position: center;
  width: 1.125rem;
}

.sidenavPaths {
  color: #44ccff;
}