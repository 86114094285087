.topBar {
    position: fixed;
    box-sizing: border-box;
    top: 0;
    height: 4.5vw;
    width: 100%;
    border-top: 1px solid #333333;
    background: #000000;
    display: flex;
    border-bottom: 0.5px solid #333333;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    z-index: 5;
    padding-left: 1.203vw;
    padding-right: 4.359vw;
  }

  .topBarRight{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .linkList{
    list-style: none;
    font-weight: 400;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 3.077vw;
  }

  a{
    text-decoration: none;
    color: #CCCCCC;
  }
  /* a:active{
    border-bottom: 1px solid #cccccc;
  } */

  .navActive{
    /* border-bottom: 1px solid #cccccc; */
    color: #cccccc6a;
  }

  .SignupButton{
    padding:0.5vw 2vw;
    background-color:#44ccff;
    border:none;
    border-radius:5px;
    cursor:pointer;
  }

  .SignupButton:hover{
    color: white;
  }